import logo from "./logo.png";
import styles from "./App.module.css";
import {
  BrowserRouter,
  useParams,
  Routes,
  Route,
  Outlet,
  Link,
} from "react-router-dom";

const Layout = () => {
  return (
    <>
      <nav className={styles.navbar}>
        <ul>
          <li>
            <Link to={"/"}>Landing Page</Link>
          </li>
          <li>
            <Link to={"/salesPage"}>Sales Page</Link>
          </li>
          <li>
            <Link to={"/blog"}>Blog Page</Link>
          </li>
        </ul>
      </nav>

      <div className={styles.root}>
        <Outlet />
      </div>
    </>
  );
};

function Home() {
  return (
    <>
      <h1>Landing Page 4017</h1>
      <article>
        <img src={logo} style={{ width: "250px" }} alt="" />
      </article>
      <Link to={`/blog/${2}`}>Blog Article {2}</Link>
    </>
  );
}
function SalesPage() {
  return (
    <>
      <h1>Sales Page 4017</h1>
      <article>
        <p>Buy</p>
        <p>-20%</p>
        <div>
          Second test go to blog with utm parameters
          <button
            style={{
              cursor: "pointer",
              width: "100px",
              height: "50px",
              marginLeft: "10px",
            }}
            disabled
            onClick={() => {
              window.open("/blog", "_blank");
            }}
          >
            go to blog
          </button>
        </div>
        <p>-50%</p>
        <p>-10%</p>
      </article>
    </>
  );
}

const blogArray = ["1", "2", "3", "4", "5"];
function Blog() {
  return (
    <>
      <h1>Blog 4017</h1>
      <ul>
        {blogArray.map((blogId) => (
          <li className="">
            <Link to={`/blog/${blogId}`}>Blog {blogId}</Link>
          </li>
        ))}
      </ul>
    </>
  );
}
function BlogArticle() {
  const { idBlog } = useParams();

  return (
    <>
      <h1>BlogArticle 4017</h1>
      <Link to={"/blog"}>Back to Blog Page</Link>
      <p>id : {idBlog}</p>

      <ul>
        {blogArray.map((blogId) => (
          <li className="">
            <Link to={`/blog/${blogId}`}>Blog {blogId}</Link>
          </li>
        ))}
      </ul>
    </>
  );
}
function NoPage() {
  return <h1>NoPage</h1>;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={`salesPage`} element={<SalesPage />} />
          <Route path={`blog`} element={<Blog />} />
          <Route path={`blog/:idBlog`} element={<BlogArticle />} />
          <Route path={"/*"} element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
